import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import groupPlusIcon from "../../../../../../../styles/svg/group-plus-icon.svg";
import groupMinusIcon from "../../../../../../../styles/svg/group-minus-icon.svg";
import { useWorkflowById } from "../../../../../../../utils/reactQuery/workflows";
import FieldCreateEditCard from "../../../../Kanban/FieldCreateEditCard";
import { useCustomSearchParams } from "../../../../../../../hooks/useCustomSearchParams";
import { createCard } from "../../../../../../../sevices/apiCards";
import AssignSubtask from "./AssignSubtask";
import ListSubtasks from "./ListSubtasks";
import { useSubtasks } from "../../../../../../../utils/reactQuery/cards";

const SubTask = ({ cardDetail, boardKey, cardsOnBoard }) => {
  const [workflowId, setWorkflowId] = useState(null);
  const [workflowSteps, setWorkflowSteps] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [milestone] = useCustomSearchParams("milestone");
  const [boardId] = useCustomSearchParams("brd_id");
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [subtaskCards, setSubtaskCards] = useState(null);

  const { subtasks } = useSubtasks(cardDetail?.card_id);

  useEffect(() => {
    if (subtasks) {
      setSubtaskCards(subtasks?.data?.data?.responseData);
    }
  }, [subtasks]);
  useEffect(() => {
    if (cardDetail) {
      setWorkflowId(cardDetail?.board?.workflow_id);
    }
  }, [cardDetail]);

  const { data } = useWorkflowById(workflowId, workflowId);

  useEffect(() => {
    if (data) {
      setWorkflowSteps(data?.data?.data?.responseData[0].workflow_step_id);
    }
  }, [data]);

  const handleCreateCard = () => {
    setOpen((prev) => !prev);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      let data = {};
      if (milestone) {
        data = {
          title,
          workflow_step_id: workflowSteps,
          board_id: id ?? boardId,
          parent_card_id: cardDetail?.card_id,
          epic_id: milestone ? milestone : "",
        };
      } else {
        data = {
          title,
          workflow_step_id: workflowSteps,
          board_id: id ?? boardId,
          parent_card_id: cardDetail?.card_id,
        };
      }
      if (title) {
        createMutate(data);
      }
    }
  };

  const { mutate: createMutate } = useMutation({
    mutationFn: createCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id ?? boardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`subTasks`, cardDetail?.card_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      toast.success(res.data.message);
      setOpen(false);
    },
    onError: (error) => {
      console.error("Error Create card", error);
      toast.error(error.response.data.message);
    },
  });

  return (
    <>
      {!cardDetail?.parent_card_id && (
        <div className="subtask-box">
          <div className="subtask-heading">
            <p>Sub tasks</p>
            <div onClick={handleCreateCard}>
              <img src={open ? groupMinusIcon : groupPlusIcon} alt="" />
            </div>
          </div>

          {subtaskCards && subtaskCards.length > 0 && boardKey && (
            <div className="subtask-outerContainer">
              {subtaskCards?.map((item) => (
                <ListSubtasks key={item.id} card={item} boardKey={boardKey} />
              ))}
            </div>
          )}
        </div>
      )}
      {open && (
        <div>
          <div className="subtask-box-input-field">
            <FieldCreateEditCard
              handleKeyDown={handleKeyDown}
              setTitle={setTitle}
              setCreateBtnOpen={null}
              minRows={1}
              subTask={true}
            />
          </div>
          <AssignSubtask cardsOnBoard={cardsOnBoard} cardDetail={cardDetail} />
        </div>
      )}
    </>
  );
};

export default SubTask;
