import React from "react";
import { useResponsive } from "../../../../hooks/useResponsive";

function MilestoneProgressBar({
  allocatedHours,
  hoursSpent,
  exceedHours,
  remainingHours,
}) {
  const isMobile = useResponsive("down", "md");

  const barMainStyle = {
    width: isMobile ? "150px" : `200px`,
    height: `10px`,
    borderRadius: "6px",
    backgroundColor: `#E0E0E0`,
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
  };

  const dropzoneStyle = (bgColor, width) => ({
    width: `${width}%`,
    height: `10px`,
    backgroundColor: bgColor,
  });

  const green =
    allocatedHours && hoursSpent
      ? Math.round(
          ((hoursSpent - exceedHours) / (allocatedHours + exceedHours)) * 100
        ) == 0
        ? 1
        : Math.round(
            ((hoursSpent - exceedHours) / (allocatedHours + exceedHours)) * 100
          )
      : 0;

  const red =
    allocatedHours && exceedHours
      ? Math.round((exceedHours / (allocatedHours + exceedHours)) * 100) == 0
        ? 1
        : Math.round((exceedHours / (allocatedHours + exceedHours)) * 100)
      : 0;

  return (
    <div>
      <div className="d-flex justify-content-space-between">
        <p className="complete-percent">
          {(allocatedHours - remainingHours)?.toFixed(2)}/{allocatedHours} hrs
        </p>
        <p className="complete-percent">{exceedHours} hrs</p>
      </div>
      <div style={barMainStyle}>
        <div
          style={{
            ...dropzoneStyle("#34C759", green),
            borderRadius: "6px 0px 0px 6px",
          }}
        ></div>
        <div
          style={{
            ...dropzoneStyle("#FF5630", red),
            borderRadius: "0px 6px 6px 0px",
          }}
        ></div>
      </div>
      <div className="d-flex justify-content-end">
        <p className="complete-percent">
          {allocatedHours
            ? Math.round(
                ((allocatedHours - remainingHours) / allocatedHours) * 100
              )
            : "0"}
          % Completed
        </p>
      </div>
    </div>
  );
}

export default MilestoneProgressBar;
