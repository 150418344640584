import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";

import lockIcon from "../../../../styles/svg/lock-milestone.svg";
import unlockIcon from "../../../../styles/svg/un-lock.svg";
import MilestoneProgressBars from "../progressBar/milestoneProgressBar";
import nextCheckpointIcon from "../../../../styles/svg/next-checkpoint.svg";
import EditMIlestone from "../../../../components/Milestone/EdiMilestone";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import {
  useCheckpoints,
  useEpic,
  useEpicThroughId,
} from "../../../../utils/reactQuery/epics";
import { useResponsive } from "../../../../hooks/useResponsive";
import MergeMilestonePopUp from "../mergeMilestone/MergeMilestonePopUp";

function MilestoneSection({ boardName }) {
  const [editMIlestone, setEditMilestone] = useState(false);
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const [allMilestones, setAllMilestones] = useState([]);
  const [openMergeMilestone, setOpenMergeMilestone] = useState(false);
  const { id } = useParams();
  const { epics: milestones } = useEpic(id);

  const isMobile = useResponsive("down", "md");
  const { epics } = useEpicThroughId(milestone);
  const { checkpoint } = useCheckpoints(milestone);

  let nextCheckpoint = checkpoint?.data?.data?.responseData?.filter((ele) => {
    return ele.is_done == false;
  })[0];

  useEffect(() => {
    if (milestones) {
      setAllMilestones(
        milestones?.data?.data?.responseData?.filter(
          (item) => item.is_locked === false
        )
      );
    }
  }, [milestones]);

  const handleMergeMilestonePopup = () => {
    setOpenMergeMilestone(true);
  };

  return (
    <>
      <div className="milestone-cont" onClick={() => setEditMilestone(true)}>
        <Stack
          direction="row"
          flexWrap={"wrap"}
          rowGap={isMobile ? 1 : 1.5}
          alignItems="center"
          justifyContent="space-between"
          spacing={isMobile ? 0 : 4}
          width="100%"
          pt={isMobile ? "4px" : ""}
        >
          <Stack direction="row" alignItems="center">
            {epics?.data?.data.responseData?.epic.is_locked ? (
              <img src={lockIcon} alt="lock icon" width={isMobile ? 14 : 20} />
            ) : (
              <img
                src={unlockIcon}
                alt="lock icon"
                width={isMobile ? 14 : 20}
              />
            )}
            <p className="milestone_name">
              {epics?.data?.data.responseData?.epic.epic_name}
            </p>
          </Stack>
          {isMobile && (
            <Stack direction="row">
              <p className="milestone_label">Target Date: </p>
              <p className="milestone_value" style={{ marginLeft: "4px" }}>
                {epics?.data?.data.responseData?.epic.target_release_date
                  ? moment(
                      epics?.data?.data.responseData?.epic.target_release_date
                    ).format("MM/DD/YYYY")
                  : "NA"}
              </p>
            </Stack>
          )}
          <Box className="milestone-progress">
            <MilestoneProgressBars
              allocatedHours={
                epics?.data?.data.responseData?.total_allocated_hours
              }
              hoursSpent={epics?.data?.data.responseData?.total_hours_spent}
              exceedHours={epics?.data?.data.responseData?.total_exceeded_hours}
              remainingHours={
                epics?.data?.data.responseData?.total_remaining_hours
              }
            />
          </Box>
          {!isMobile && (
            <Stack>
              <Stack direction="row" className="d-flex align-items-center">
                <p className="milestone_label">Target Date: </p>
                <p className="milestone_value" style={{ marginLeft: "4px" }}>
                  {epics?.data?.data.responseData?.epic.target_release_date
                    ? moment(
                        epics?.data?.data.responseData?.epic.target_release_date
                      ).format("MM/DD/YYYY")
                    : "NA"}
                </p>
              </Stack>
              {!nextCheckpoint && (
                <div className="d-flex justify-content-end">
                  <p
                    className="merge-mileston mt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMergeMilestonePopup();
                    }}
                  >
                    Merge Milestone
                  </p>
                </div>
              )}
            </Stack>
          )}

          {nextCheckpoint ? (
            <Stack>
              <Stack direction="row" spacing={1}>
                <img
                  src={nextCheckpointIcon}
                  alt="checkpoint"
                  width={isMobile ? "14px" : ""}
                  // style={{ marginLeft: "40px" }}
                />
                <p
                  className={"milestone_value"}
                  style={{ color: "#212B3699", fontSize: "14px" }}
                >
                  {nextCheckpoint?.checkpoint_name}:{" "}
                  {moment(nextCheckpoint?.datetime).format("MM/DD/YYYY")}
                </p>
              </Stack>
              <div className="d-flex justify-content-end">
                <p
                  className="merge-mileston mt-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMergeMilestonePopup();
                  }}
                >
                  Merge Milestone
                </p>
              </div>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </div>
      {editMIlestone && (
        <EditMIlestone
          boardName={boardName}
          open={editMIlestone}
          handleClose={() => setEditMilestone(false)}
        />
      )}
      {openMergeMilestone && (
        <MergeMilestonePopUp
          open={openMergeMilestone}
          close={setOpenMergeMilestone}
          unlockedMilestone={allMilestones}
          isSelectSourceMilestone={true}
          SelectedSourceMileStone={epics}
        />
      )}
    </>
  );
}

export default MilestoneSection;
