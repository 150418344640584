import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import manageColumnIcon from "../../../../styles/svg/Vector.svg";
import MuiTable from "../../../admin/board/components/BoardTable/MuiTbale";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { useCards } from "../../../../utils/reactQuery/cards";
import { dataGridColumn } from "../../../../components/dataGridColumn";
import OpenCardDetail from "../Kanban/openCardDetail";
import addIcon from "../../../../styles/svg/addIcon.svg";
import CreateCardDialogListview from "./CreateCardDialogListview";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import GlobalTableComponent from "../../../../components/Table/Table/Table";
import CardTitleField from "./CardTitleField";
import ChangeStatus from "../../../../components/CardComponents/ChangeStatus";
import CustomizedProgressBars from "../progressBar/ProgressBar";
import ListAssignCard from "./AssignCard";
import ChangeMilestone from "./ChangeMilestone";
import CardId from "../../../../components/dataGridColumn/CardId";
import ChildTaskData from "./ChildTaskData";
import Loader from "../../../../components/Loader";

export default function ListView({ workflowId, boardName, cards, isLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id: boardId } = useParams();
  const [columns, setColumns] = useState(dataGridColumn);
  const [expandcardId, setExpandCardId] = useState(null);
  const [subtaskCards, setSubtaskCards] = useState([]);
  const [isSubtaskLoading, setIsSubtaskLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedColumn, setSelectedColumn] = useState({
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
  });
  const [boardCards, setBoardCards] = useState(cards?.data?.data?.responseData);
  const { data: workflows } = useWorkflowById(workflowId, boardId);
  const [openCreateCard, setOpenCreateCard] = useState(false);
  const filterUser = searchParams.get("search");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const searchText = useSelector((state) => state.search.searchText);
  const cardId = searchParams.get("card_id");
  const statusSearch = searchParams.get("workflow_Step");
  const currentUserIdVariable = currentUserId();

  const havePermissionToEdit = (data) => {
    if (
      isAdmin() ||
      boardName?.owner_user_id == currentUserIdVariable ||
      data?.assigned_to_user_id == currentUserIdVariable ||
      data?.reporter == currentUserIdVariable
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectColumn = (columnId) => {
    setColumns(
      columns.map((item) =>
        item.id === columnId ? { ...item, isHidden: !item.isHidden } : item
      )
    );
    const temp = { ...selectedColumn };
    temp[columnId] = !selectedColumn[columnId];
    setSelectedColumn(temp);
  };

  const workflowSteps = workflows?.data?.data?.responseData;

  useEffect(() => {
    const debounceDelay = 1000;
    let allFilteredCards = cards?.data.data.responseData;
    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards?.filter(
          (cardData) =>
            (cardData?.card.title
              ? `${cardData.card.title}`.toLowerCase().includes(searchText)
              : "") ||
            (cardData.card.assigned_to_user?.first_name
              ? `${cardData.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );
        setBoardCards(allFilteredCards);
      }
    }, debounceDelay);
    if (filterUser) {
      allFilteredCards = allFilteredCards?.filter(
        (cardData) => cardData.card.assigned_to_user_id == filterUser
      );
    }
    if (epicSearch && epicSearch?.length > 0) {
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        epicSearch.includes(cardData.card.epic_id)
      );
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards?.filter(
        (cardData) => cardData?.card.epic_id == null
      );
    }

    if (searchText) {
      debouncedFilter();
    }

    if (statusSearch) {
      allFilteredCards = allFilteredCards?.filter((cardData) => {
        return cardData.card.workflow_step_id == statusSearch;
      });
    }

    setBoardCards(allFilteredCards);

    return () => {
      debouncedFilter.cancel();
    };
  }, [
    searchText,
    filterUser,
    epicSearch,
    boardId,
    cards,
    statusSearch,
    noMileStoneSearch,
  ]);

  const handleClickOpen = () => {
    setOpenCreateCard(true);
  };

  const handleExpandedData = (cardId) => {
    setExpandCardId(cardId);
  };

  useEffect(() => {
    let project;

    if (subtaskCards && boardCards) {
      const newSubtask = subtaskCards.map((item) => ({ ...item?.card }));
      project = boardCards?.map((item, idx) => {
        if (item?.card?.card_id === expandcardId) {
          return { ...item, subRow: newSubtask };
        }

        return item;
      });
      setBoardCards(project);
    }
  }, [subtaskCards]);

  const loader = {
    style: { color: "black" || "#ffffff" },
    size: 20,
  };

  const subTableProps = {
    // isCheckbox: true,
    isCollapsible: true,
    // isDraggableRow : true,
    // isHiddenCheckBoxIcon : true,
    // isHiddenDraggableRowIcon : true,
    isHiddenRowExpandIcon: true,
    isHeaderHidden: true,
    isLoading: isSubtaskLoading,
    loadingProps: loader,
  };
  console.log("boardCard", boardCards);
  return (
    <>
      {expandcardId && (
        <ChildTaskData
          cardId={expandcardId}
          setSubtaskCards={setSubtaskCards}
          setIsSubtaskLoading={setIsSubtaskLoading}
        />
      )}
      {boardCards && cardId && <OpenCardDetail cardsOnBoard={boardCards} />}
      {openCreateCard && workflowSteps && (
        <CreateCardDialogListview
          open={openCreateCard}
          handleClose={() => setOpenCreateCard(false)}
          workflowSteps={workflowSteps}
        />
      )}
      <div>
        <Stack
          direction="row"
          className="d-flex justify-content-end align-items-center"
          mb={3}
        >
          <Button
            onClick={handleClickOpen}
            className="theme-bg-color capitalize mr-3"
            variant="contained"
            startIcon={<img src={addIcon} alt="addSubtaskIcone" />}
            sx={{
              position: "static",
              "&:hover": {
                backgroundColor: "#1B3E61",
                border: "none",
                boxShadow: 0,
              },
            }}
          >
            Create Card
          </Button>
          <Stack direction="row" justifyContent="end">
            <img
              src={manageColumnIcon}
              width={14}
              alt="manage column"
              style={{ cursor: "pointer" }}
            />
            <Typography
              className="manage-board"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              Manage Board Columns
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ height: "calc(94vh - 154px )" }}>
          <GlobalTableComponent
            data={
              boardCards
                ? boardCards?.map((boardCard) => {
                    const isEditAllowed = havePermissionToEdit(boardCard?.card);
                    const boardKey = boardCard?.key;
                    return {
                      id: boardCard?.card?.card_id,

                      col1: (
                        <CardId
                          id={boardCard?.card?.card_id}
                          boardKey={boardKey}
                        />
                      ),

                      col2: (
                        <CardTitleField
                          cardId={boardCard?.card?.card_id}
                          value={boardCard?.card?.title}
                          isReadOnly={!isEditAllowed}
                          height={"auto"}
                          fontColor={"rgba(33, 43, 54, 0.8)"}
                        />
                      ),

                      col3: boardName?.workflow_id && (
                        <ChangeStatus
                          cardId={boardCard?.card?.card_id}
                          workflow_id={boardName?.workflow_id}
                          boardId={boardCard?.card?.board_id}
                          checkCondition={isEditAllowed}
                          workflowStepId={boardCard?.card?.workflow_step_id}
                        />
                      ),
                      col5: (
                        <ListAssignCard
                          cardData={boardCard?.card}
                          name={`${boardCard?.card?.assigned_to_user?.first_name} ${boardCard?.card?.assigned_to_user?.last_name}`}
                          cardId={boardCard?.card?.card_id}
                          tooltipName={`${boardCard?.card?.assigned_to_user?.first_name} ${boardCard?.card?.assigned_to_user?.last_name}`}
                          listView={true}
                        />
                      ),

                      col6: boardCard?.card?.labels?.join(" "),
                      col7: (
                        <ChangeMilestone
                          project={boardCard?.card}
                          listView={true}
                        />
                      ),
                      col8: (
                        <p className="listView-fontColor">
                          {boardCard?.card?.allocated_hours}{" "}
                          {boardCard?.card?.allocated_hours ? "hours" : ""}
                        </p>
                      ),
                      col9: (
                        <p className="listView-fontColor">
                          {boardCard?.total_hours_spent}{" "}
                          {boardCard?.total_hours_spent
                            ? boardCard?.total_hours_spent == 1
                              ? "hour"
                              : "hours"
                            : ""}
                        </p>
                      ),

                      col10: (
                        <Stack sx={{ width: "100%" }}>
                          <CustomizedProgressBars
                            allocatedHours={boardCard?.card?.allocated_hours}
                            hoursSpent={
                              boardCard?.total_hours_spent
                                ? boardCard?.total_hours_spent
                                : "0"
                            }
                            listView={true}
                          />
                        </Stack>
                      ),
                      col11: (
                        <p className="listView-fontColor">
                          {boardCard?.card?.due_date
                            ? moment(boardCard?.card?.due_date)
                                .local()
                                .format("MMMM D,YYYY")
                            : ""}
                        </p>
                      ),

                      col12: (
                        <p className="listView-fontColor">
                          {boardCard?.card?.date_created
                            ? moment(boardCard?.card?.date_created)
                                .local()
                                .format("MMMM D,YYYY")
                            : ""}
                        </p>
                      ),

                      col13: (
                        <p className="listView-fontColor">
                          {boardCard?.card.reported_by?.first_name || ""}
                          {boardCard?.card?.reported_by?.last_name || ""}
                        </p>
                      ),

                      subRow:
                        boardCard?.subRow && boardCard?.subRow?.length > 0
                          ? boardCard?.subRow?.map((boardCard, idx) => {
                              return {
                                id: boardCard?.card_id,

                                col1: (
                                  <CardId
                                    id={boardCard?.card_id}
                                    boardKey={boardKey}
                                  />
                                ),

                                col2: (
                                  <CardTitleField
                                    cardId={boardCard?.card_id}
                                    value={boardCard?.title}
                                    isReadOnly={!isEditAllowed}
                                    height={"auto"}
                                    fontColor={"rgba(33, 43, 54, 0.8)"}
                                  />
                                ),

                                col3: boardName?.workflow_id && (
                                  <ChangeStatus
                                    cardId={boardCard?.card_id}
                                    workflow_id={boardName?.workflow_id}
                                    boardId={boardCard?.board_id}
                                    checkCondition={isEditAllowed}
                                    workflowStepId={boardCard?.workflow_step_id}
                                  />
                                ),
                                col5: (
                                  <ListAssignCard
                                    cardData={boardCard}
                                    name={`${boardCard?.assigned_to_user?.first_name} ${boardCard?.assigned_to_user?.last_name}`}
                                    cardId={boardCard?.card_id}
                                    tooltipName={`${boardCard?.assigned_to_user?.first_name} ${boardCard?.assigned_to_user?.last_name}`}
                                    listView={true}
                                  />
                                ),

                                col6: boardCard?.labels?.join(" "),
                                col7: (
                                  <ChangeMilestone
                                    project={boardCard}
                                    listView={true}
                                  />
                                ),

                                col8: (
                                  <p className="listView-fontColor">
                                    {boardCard?.allocated_hours}
                                  </p>
                                ),
                                col9: (
                                  <p className="listView-fontColor">
                                    {boardCard?.total_hours_spent}
                                  </p>
                                ),

                                col10: (
                                  <Stack sx={{ width: "100%" }}>
                                    <CustomizedProgressBars
                                      allocatedHours={
                                        boardCard?.allocated_hours
                                      }
                                      hoursSpent={
                                        boardCard?.total_hours_spent
                                          ? boardCard?.total_hours_spent
                                          : "0"
                                      }
                                      listView={true}
                                    />
                                  </Stack>
                                ),
                                col11: (
                                  <p className="listView-fontColor">
                                    {boardCard?.due_date
                                      ? moment(boardCard?.due_date)
                                          .local()
                                          .format("MMMM D,YYYY")
                                      : ""}
                                  </p>
                                ),
                                col12: (
                                  <p className="listView-fontColor">
                                    {boardCard?.date_created
                                      ? moment(boardCard?.date_created)
                                          .local()
                                          .format("MMMM D,YYYY")
                                      : ""}
                                  </p>
                                ),
                                col13: (
                                  <p className="listView-fontColor">
                                    {boardCard?.reported_by?.first_name || ""}
                                    {boardCard?.reported_by?.last_name || ""}
                                  </p>
                                ),
                              };
                            })
                          : null,
                    };
                  })
                : []
            }
            // headers={columns.filter((column) => selectedColumn[column?.id])}
            // subHeader={columns.filter((column) => selectedColumn[column?.id])}
            headers={columns}
            subHeader={columns.map((item) =>
              item.id === "col1"
                ? {
                    ...item,
                    alignContent: "center",
                  }
                : item
            )}
            isCollapsible={true}
            isDraggableColumn={true}
            isPagination={true}
            rowCount={10}
            handleExpandedData={handleExpandedData}
            subTableProps={subTableProps}
            tableBoxClass={"parent-table-box"}
            tableContainerClass={"parent-table-boarder"}
            isLoading={isLoading}
            loadingProps={loader}
            loadingClass={"h-70vh"}
            headerClass={"table-header-listview"}
          />
        </Box>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(false)}
          MenuListProps={{
            "aria-labelledby": "epics-board-dropdown",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          className="assign-board-owner-list"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Object.values(selectedColumn).every(
                    (value) => value === true
                  )}
                  onChange={() =>
                    setSelectedColumn((prevObj) => {
                      // Create a new object with all values set to true
                      const newObj = Object.keys(prevObj).reduce((acc, key) => {
                        acc[key] = true;
                        return acc;
                      }, {});
                      return newObj;
                    })
                  }
                />
              }
              label="Select All"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col3}
                  onChange={() => handleSelectColumn("col3")}
                />
              }
              label="Card Status"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col5}
                  onChange={() => handleSelectColumn("col5")}
                />
              }
              label="Assignee"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col6}
                  onChange={() => handleSelectColumn("col6")}
                />
              }
              label="Label"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col7}
                  onChange={() => handleSelectColumn("col7")}
                />
              }
              label="Epic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col8}
                  onChange={() => handleSelectColumn("col8")}
                />
              }
              label="Alloted Hours"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col9}
                  onChange={() => handleSelectColumn("col9")}
                />
              }
              label="Hours Spent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col10}
                  onChange={() => handleSelectColumn("col10")}
                />
              }
              label="Time Tracking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col11}
                  onChange={() => handleSelectColumn("col11")}
                />
              }
              label="Due Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col12}
                  onChange={() => handleSelectColumn("col12")}
                />
              }
              label="Current Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col13}
                  onChange={() => handleSelectColumn("col13")}
                />
              }
              label="Reporter"
            />
          </FormGroup>
        </Menu>
      </div>
    </>
  );
}
