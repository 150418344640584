import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import Switch from "@mui/material/Switch";
import { TextField, Toolbar, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const label = { inputProps: { "aria-label": "Switch demo" } };

const TableToolBar = ({
  selectedCount,
  handleExportData,
  headers,
  handleShowHideColumn,
  toolbarClass,
  isSearch,
  searchInputClass,
  handleChange,
  searchProps,
  isToggleColumn,
  isDataExport,
  toggleColumnButtonProps,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar className={`global-toolbar ${toolbarClass}`}>
      {isDataExport && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selectedCount > 0 && (
            <>
              <IconButton aria-label="export" onClick={handleExportData}>
                <FileDownloadIcon />
              </IconButton>
              {selectedCount} row selected
            </>
          )}
        </Typography>
      )}

      <React.Fragment>
        {isSearch && (
          <TextField
            id="search"
            type="search"
            placeholder="Search"
            onChange={handleChange}
            className={`global-search ${searchInputClass}`}
            {...searchProps}
          />
        )}

        {isToggleColumn && (
          <Tooltip title="Show / Hide Column">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : "true"}
              {...toggleColumnButtonProps}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          // onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {headers &&
            headers.length > 0 &&
            headers.map((header) => (
              <MenuItem key={header?.id}>
                {header.label}
                <Switch
                  {...label}
                  onClick={() => {
                    handleShowHideColumn(header?.id);
                  }}
                  checked={header?.isHidden ? true : false}
                />
              </MenuItem>
            ))}
        </Menu>
      </React.Fragment>
    </Toolbar>
  );
};

export default TableToolBar;
