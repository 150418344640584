import React from "react";
import { TablePagination as MUITablePagination } from "@mui/material";
import Stack from "@mui/material/Stack";

const TablePaginationComponent = ({
  rowsPerPageOptions,
  component,
  rowCount,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <>
      <Stack spacing={2}>
        <MUITablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component={component}
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Stack>
    </>
  );
};

export default TablePaginationComponent;
