import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isAdmin } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import EditTitle from "../../../board/components/Kanban/EditTitle";
import { Tooltip } from "@mui/material";
import AssignCard from "../../../board/components/Kanban/AssignCard";
import CustomizedProgressBars from "../../../board/components/progressBar/ProgressBar";
import DeleteCard from "../../../board/components/Kanban/DeleteCard";
import checkMark from "../../../../styles/svg/checkMark.svg";
import CustomDateFormatter from "../../../board/components/dateTimeFormateConverter/dateTimeFormateConverter";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";
import EditIcon from "../../../../styles/svg/edit-title.svg";
import moment from "moment";
import mediumPriorityIcon from "../../../../styles/svg/medium_priority.svg";
import highPriorityIcon from "../../../../styles/svg/high_priority.svg";
import lowPriorityIcon from "../../../../styles/svg/low_priority.svg";
import criticalPriorityIcon from "../../../../styles/svg/critical_priority.svg";

function KanbanCard({
  project: card,
  onDragEnd,
  index,
  id,
  callback: refetch,
}) {
  const { id: boardId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [project, setProject] = useState(card);
  const [setMultipleSearchParams] = useSetMultipleParams();
  // const searchParams = new URLSearchParams(useSearchParams());
  // const setSearchParams = useSearchParams();
  const [collapsed, setCollapsed] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [deleteCardHoverIndex, setDeleteCardHoverIndex] = useState(null);
  const currentUser = useSelector((state) => state.users.logInUser);
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", boardId]);
  const [isEditing, setIsEditing] = useState(false);
  const tooltipName = `${project.card.assigned_to_user?.first_name || ""} ${
    project.card.assigned_to_user?.last_name || ""
  }`;
  const firstName = project.card.assigned_to_user?.first_name[0];
  const lastName = project.card.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;

  const handleDetails = (e) => {
    // e.stopPropagation();
    // setCardId(project.card.card_id);
    // console.log("searchParams",project.card?.card_id);
    // console.log("searchParams",project.card);
    // setShowDetails(true);
    // setSearchParams({'card_id': project.card?.card_id })
    setMultipleSearchParams([
      { name: "card_id", value: project.card?.card_id },
      { name: "brd_id", value: project.card?.board_id },
    ]);
  };

  const accessForDeleteCard =
    isAdmin() ||
    currentUser?.user_id == cachedData?.data?.data.responseData?.owner_user_id;

  const accessForAssignOrEditTitle =
    currentUser?.user_id == project.card?.reporter ||
    currentUser?.user_id == project.board_admin ||
    isAdmin();

  const showEditButton = () => {
    if (accessForAssignOrEditTitle) setHoveredIndex(true);
  };

  const showDeleteButton = () => {
    if (accessForDeleteCard) {
      setDeleteCardHoverIndex(true);
    }
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const formatTimeFromNow = (createdDate) => {
    const newDate = moment.utc(createdDate);
    const localDate = newDate.local().toDate();
    // console.log("INPUTlocal1", createdDate, localDate);

    const now = moment();
    const diffInMinutes = now.diff(localDate, "minutes");
    const diffInHours = now.diff(localDate, "hours");
    const diffInDays = now.diff(localDate, "days");
    const diffInMonths = now.diff(localDate, "months");
    const diffInYears = now.diff(localDate, "years");
    if (diffInMinutes === 0) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} Min${diffInMinutes === 1 ? "" : "s"} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} Hr${diffInHours === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} Day${diffInDays === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 365) {
      return `${diffInMonths} Month${diffInMonths === 1 ? "" : "s"} ago`;
    } else {
      return `${diffInYears} Year${diffInYears === 1 ? "" : "s"} ago`;
    }
  };

  const openEditField = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  return (
    <>
      <Tooltip title={project.board_name}>
        <div
          className="card-style cursor-pointer"
          // className={
          //   project.card.parent_card_id && !project.is_completed
          //     ? "card-style-blur cursor-pointer"
          //     : "card-style cursor-pointer"
          // }
          draggable={true}
          onDragEnd={(e) => {
            onDragEnd(e, project.card);
          }}
          onClick={handleDetails}
          onMouseEnter={() => {
            showEditButton();
            showDeleteButton();
          }}
          onMouseLeave={() => {
            setHoveredIndex(null);
            setDeleteCardHoverIndex(null);
          }}
        >
          {project?.card?.parent_card_id && (
            <span className="related_time parent-title-id mb-2">
              {`${project.key}`.toUpperCase()}-{project?.card?.parent_card_id}
            </span>
          )}
          <div className="d-flex justify-content-space-between">
            <div className="d-flex align-items-center">
              <div className="avatar-container">
                {project.assigned_user_last_seen && (
                  <Tooltip
                    title={
                      <CustomDateFormatter
                        date={project.assigned_user_last_seen}
                      />
                    }
                    arrow
                  >
                    <img
                      src={checkMark}
                      alt="checkMark"
                      className="cursor-pointer checkMark-position"
                    />
                  </Tooltip>
                )}
                <div>
                  <AssignCard
                    tooltipName={tooltipName}
                    project={project.card}
                    name={name}
                    accessForAssign={accessForAssignOrEditTitle}
                    cardId={project?.card.card_id}
                  />
                </div>
              </div>
              <div className="ml-1">
                <p className="d-flex align-items-center">
                  <p className="card-id-number">
                    {`${project.key}`.toUpperCase()}-{project.card.card_id}
                  </p>
                </p>
                <p className="related_time">
                  {formatTimeFromNow(project.card.date_created)}
                </p>
              </div>

              <img
                className="priority_icon"
                src={
                  project?.card.priority === "Critical"
                    ? criticalPriorityIcon
                    : project?.card.priority === "High"
                    ? highPriorityIcon
                    : project?.card.priority === "Medium"
                    ? mediumPriorityIcon
                    : project?.card.priority === "Low"
                    ? lowPriorityIcon
                    : ""
                }
                alt="priority"
              />
            </div>
            <div className="d-flex align-items-center">
              {hoveredIndex && (
                <img
                  src={EditIcon}
                  onClick={openEditField}
                  className="cursor-pointer"
                  alt="edit"
                />
              )}
              {deleteCardHoverIndex && (
                <DeleteCard
                  cardId={project?.card.card_id}
                  boardId={boardId}
                  kanbanCardDelete={true}
                />
              )}
            </div>
          </div>
          <div className="subtasks subtasks-uline ml-44">
            {project?.sub_tasks?.length > 0 &&
              project.sub_tasks.map((subtask) => (
                <div
                  key={subtask?.card_id}
                  className="subtask d-flex align-items-center justify-content-space-between "
                >
                  <div>
                    <span className="related_time">
                      {`${project.key}`.toUpperCase()}-{subtask?.card_id}
                    </span>
                  </div>
                  <div className="related_time">
                    {subtask?.total_hours_spent !== null
                      ? subtask?.total_hours_spent
                      : 0}
                    /
                    {subtask?.allocated_hours !== null
                      ? subtask?.allocated_hours
                      : 0}
                  </div>
                  <div className="related_time">
                    {subtask?.allocated_hours && subtask?.total_hours_spent
                      ? Math.round(
                          (subtask?.total_hours_spent /
                            subtask?.allocated_hours) *
                            100
                        )
                      : 0}
                    %
                  </div>
                </div>
              ))}
          </div>
          <div className="mt-2">
            <EditTitle
              project={project}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </div>
          {collapsed ? null : (
            <div>
              <strong>Description: </strong>
              {project.card.description}
              <br />
            </div>
          )}

          <div className="d-flex">
            {project.card.labels?.[0] != "" && (
              <div className="d-flex">
                {project.labels.map((el) => (
                  <div className="card-level-tag mr-1">
                    <p className="card-level-tag-text">{el}</p>
                  </div>
                ))}
              </div>
            )}

            {project.card?.epic && (
              <div className="d-flex">
                <div className="card-epic-tag mr-1">
                  <p className="card-epic-tag-text">
                    {project.card?.epic?.epic_name}
                  </p>
                </div>
              </div>
            )}
          </div>
          <CustomizedProgressBars
            allocatedHours={project?.card?.allocated_hours}
            hoursSpent={
              project?.total_hours_spent ? project?.total_hours_spent : 0
            }
            showOnCardOnBoard={true}
          />
        </div>
      </Tooltip>
    </>
  );
}

export default KanbanCard;
