import { TableCell, TableRow } from "@mui/material";
import React from "react";

const NoTableDataRow = ({
  noDataClass,
  noDataMessage,
  colSpanCount,
  //   rowSpanCount,
}) => {
  return (
    <>
      <TableRow>
        <TableCell
          colSpan={colSpanCount}
          className={`global-no-data-message ${noDataClass}`}
          align="center"
        >
          {noDataMessage}
        </TableCell>
      </TableRow>
    </>
  );
};

export default NoTableDataRow;
