import { objectToFormData } from "../utils/helpers";
import Api from "./axios";

export const getBoards = async () => {
  return await Api.get(`/boards`);
};

export const deleteBoard = async (board_id) => {
  return await Api.delete(`/board/${board_id}`);
};

export const getBoardUsers = (boardId) => {
  return Api.get(`/board_user/${boardId}`);
};

export const createBoard = async (data) => {
  return await Api.post(`/board`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const updateBoard = async (data) => {
  return await Api.put(`/board/${data.board_id}`, objectToFormData(data.data));
};

export const archiveBoard = async ({ id, data }) => {
  // console.log(data);
  return await Api.put(`/board/${id}`, data);
};

export const getBoardName = async (boradId) => {
  return await Api.get(`/board/${boradId}`);
};

export const getAttachments = async (id) => {
  return await Api.get(`/board_attachment/${id}`);
};

export const getDownloadAttachment = async (id) => {
  return await Api.get(`/attachment/download/${id}`);
};

export const createAttachments = async (data) => {
  return await Api.post(`/board_attachment`, data);
};

export const deleteAttachments = async (attachmentId) => {
  return await Api.delete(`/board_attachment/${attachmentId}`);
};

export const assignUser = async (data) => {
  return await Api.post(`/board_user`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const deleteBoardUsers = (data) => {
  return Api.delete(`/board_user/${data.board_id}/${data.user_id}`);
};

export const getLebels = async (boradId) => {
  return await Api.get(`/labels/${boradId}`);
};

export const getBoardsWithSameWorkFlowId = async (boradId) => {
  // console.log("getBoardsWithSameWorkFlowId",boradId)
  return await Api.get(`/boards/${boradId}`);
};

export const MergeBoards = async (data) => {
  return await Api.post(`/board/merge`, objectToFormData(data));
};
