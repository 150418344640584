import React from "react";

export const dataGridColumn = [
  {
    id: "col1",
    label: "Card Id",
    width: 100,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,
  },
  {
    id: "col2",
    label: "Card Title",
    width: 270,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: true,
    hide: true,
    isHidden: false,
  },
  {
    id: "col3",
    label: "Card Status",
    width: 182,
    // type: "boolean",
    headerAlign: "center",
    flex: 1,
    sortable: true,
    resizable: false,
    align: "center",
    isHidden: false,
    alignContent: "center",
  },

  {
    id: "col5",
    label: "Assignee",
    width: 236,
    flex: 1,
    sortable: true,
    resizable: false,
    isHidden: false,
  },
  {
    id: "col6",
    label: "Label",
    width: 136,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,
    renderCell: (value) => (
      <span style={{ color: "#FD7401" }}>{value?.value}</span>
    ),
  },
  {
    id: "col7",
    label: "Milestone",
    width: 182,
    flex: 1,
    sortable: true,
    resizable: false,
    isHidden: false,
  },
  {
    id: "col8",
    label: "Alloted Hours",
    width: 182,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,

    renderCell: (value) => (
      <span>
        {value?.value} {value?.value ? "hours" : ""}
      </span>
    ),
  },
  {
    id: "col9",
    label: "Hours Spent",
    width: 182,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,
    renderCell: (value) => (
      <span>
        {value?.value}{" "}
        {value?.value ? (value?.value == 1 ? "hour" : "hours") : ""}
      </span>
    ),
  },
  {
    id: "col10",
    label: "Time Tracking",
    width: 206,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,
  },
  {
    id: "col11",
    label: "Due Date",
    width: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,
    renderCell: (value) => <span>{value?.value}</span>,
  },
  {
    id: "col12",
    label: "Created Date",
    width: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,
    renderCell: (value) => <span>{value?.value}</span>,
  },
  {
    id: "col13",
    label: "Reporter",
    width: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    isHidden: false,
    renderCell: (value) => <span>{value?.value}</span>,
  },
];
