import React from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";

function CardId({ id, boardKey }) {
  const [value, setValue] = useCustomSearchParams("card_id");

  return (
    <div>
      <p
        className="card-anchor size-14"
        onClick={() => setValue("card_id", id)}
      >
        {boardKey}-{id}
      </p>
    </div>
  );
}

export default CardId;
