import React from "react";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./tableStyle.css";

const DraggableColumn = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
    textAlign: "center",
    width: "100px",
  };

  return (
    <span ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </span>
  );
};

const TableHeaderComponent = ({
  headers,
  headerClass,
  isCheckbox,
  isCollapsible,
  isDraggableRow,
  handleSelectAllClick,
  selectedCount,
  dataCount,
  orderBy,
  order,
  createSortHandler,
  selectAllCheckBoxProps,
  align = "left",
  isColumnPinned = false,
}) => {
  return (
    <>
      <TableHead className={`global-header ${headerClass}`}>
        <TableRow>
          {isDraggableRow && (
            <TableCell
              sx={{
                position: isColumnPinned ? "sticky" : undefined,
                left: 0,
                zIndex: 10,
                width: "40px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              className={`global-header-column`}
            />
          )}

          {isCheckbox && (
            <TableCell
              sx={{
                position: isColumnPinned ? "sticky" : undefined,
                left: isDraggableRow ? 70 : 0,
                zIndex: 10,
                width: "40px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              padding="checkbox"
              className={`global-header-column`}
            >
              <Checkbox
                color="primary"
                indeterminate={selectedCount > 0 && selectedCount < dataCount}
                checked={dataCount > 0 && selectedCount === dataCount}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all",
                }}
                {...selectAllCheckBoxProps}
              />
            </TableCell>
          )}

          {isCollapsible && (
            <TableCell
              sx={{
                position: isColumnPinned ? "sticky" : undefined,
                left:
                  isDraggableRow && isCheckbox
                    ? 140
                    : isCheckbox || isDraggableRow
                    ? 70
                    : 0,
                zIndex: 10,
                width: "40px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              className={`global-header-column`}
            />
          )}

          {headers &&
            headers?.length > 0 &&
            headers?.map((header, idx) => {
              if (!header?.isHidden) {
                return (
                  <TableCell
                    key={header?.id}
                    align={header?.align || align}
                    width={header?.width}
                    className={`global-header-column ${header?.columnStyle}`}
                    sortDirection={orderBy === header?.id ? order : false}
                    style={{
                      position:
                        (header?.isLeftPin || header?.isRightPin) &&
                        isColumnPinned &&
                        !isCollapsible
                          ? "sticky"
                          : undefined,
                      left:
                        header?.isLeftPin &&
                        idx == 0 &&
                        isColumnPinned &&
                        !isCollapsible
                          ? 102
                          : isColumnPinned &&
                            !isCollapsible &&
                            header?.isLeftPin &&
                            idx == 1
                          ? 260
                          : undefined,
                      right:
                        header?.isRightPin &&
                        idx == headers.length - 1 &&
                        isColumnPinned &&
                        !isCollapsible
                          ? 0
                          : isColumnPinned &&
                            !isCollapsible &&
                            header?.isRightPin &&
                            idx == headers.length - 2
                          ? 139
                          : undefined,
                      zIndex: header?.isLeftPin && 5,
                    }}
                  >
                    {header?.isSortable ? (
                      <TableSortLabel
                        active={orderBy === header?.id}
                        direction={orderBy === header?.id ? order : "asc"}
                        onClick={createSortHandler(header?.id)}
                      >
                        <DraggableColumn key={header?.id} id={header?.id}>
                          {header?.label}
                        </DraggableColumn>
                      </TableSortLabel>
                    ) : (
                      <DraggableColumn key={header?.id} id={header?.id}>
                        {header?.label}
                      </DraggableColumn>
                    )}
                  </TableCell>
                );
              }
            })}
        </TableRow>
      </TableHead>
    </>
  );
};

export default TableHeaderComponent;
