import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  Checkbox,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CSS } from "@dnd-kit/utilities";
import GlobalTableComponent from "./Table";

const TableRowComponent = ({
  isCollapsible = false,
  isItemSelected = false,
  isCheckbox = false,
  labelId,
  row,
  handleRowSelect,
  subHeaderClass,
  subHeader,
  isDraggableRow = false,
  headers,
  align = "left",
  isColumnPinned = false,
  handleExpandedData,
  subTableProps,
  isHiddenCheckBoxIcon,
  isHiddenDraggableRowIcon,
  isHiddenRowExpandIcon,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row?.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [rowExpand, setRowExpand] = React.useState({
    id: null,
    isToggle: false,
  });

  const handleRowExpand = (id) => {
    setRowExpand((prevState) => {
      if (prevState.id === id && prevState.isToggle) {
        return { id: null, isToggle: false };
      }
      return { id, isToggle: true };
    });
  };

  return (
    <>
      <TableRow
        key={row.id}
        ref={(isDraggableRow && setNodeRef) || null}
        style={(isDraggableRow && style) || {}}
        // sx={{
        //   // "&:last-child td, &:last-child th": { border: 0 },
        //   "& > *": { border: "unset !important" },
        // }}
        sx={{ "& > *": { borderBottom: rowExpand?.id && "unset !important" } }}
        tabIndex={-1}
        hover
      >
        {isDraggableRow && (
          <TableCell
            sx={{
              position: isColumnPinned ? "sticky" : undefined,
              left: 0,
              zIndex: 10,
              width: "40px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            {!isHiddenDraggableRowIcon && (
              <DragHandleIcon
                {...attributes}
                {...listeners}
                style={{ cursor: "grab" }}
              />
            )}
          </TableCell>
        )}

        {isCheckbox && (
          <TableCell
            sx={{
              position: isColumnPinned ? "sticky" : undefined,
              left: isDraggableRow ? 70 : 0,
              zIndex: 10,
              width: "40px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
            padding="checkbox"
          >
            {!isHiddenCheckBoxIcon && (
              <Checkbox
                color="primary"
                checked={isItemSelected}
                onClick={(event) => {
                  handleRowSelect(event, row?.id);
                }}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            )}
          </TableCell>
        )}
        {isCollapsible && (
          <TableCell
            sx={{
              position: isColumnPinned ? "sticky" : undefined,
              left:
                isDraggableRow && isCheckbox
                  ? 140
                  : isCheckbox || isDraggableRow
                  ? 70
                  : 0,
              zIndex: 10,
              width: "40px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            {!isHiddenRowExpandIcon && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowExpand(row?.id);
                }}
              >
                {rowExpand.id === row?.id && rowExpand.isToggle ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon
                    onClick={() => handleExpandedData(row?.id)}
                  />
                )}
              </IconButton>
            )}
          </TableCell>
        )}

        {headers &&
          headers.length > 0 &&
          headers.map((header, index) =>
            header.id === "id" ||
            Array.isArray(row[header.id]) ||
            header?.isHidden ? null : (
              <TableCell
                key={header.id}
                width={header?.width}
                align={header?.alignContent || "left"}
              >
                {row[header.id]}
              </TableCell>
            )
          )}
      </TableRow>

      {isCollapsible && (
        <TableRow>
          <TableCell
            style={{ padding: 0, paddingTop: 0 }}
            colSpan={
              isCheckbox && isDraggableRow
                ? headers.length + 3
                : isCheckbox || isDraggableRow
                ? headers.length + 2
                : headers.length + 1
            }
            sx={{
              border: "none !important",
              "& .MuiTableCell-root": {
                border: "none !important",
              },
            }}
          >
            <Collapse
              in={row?.id === rowExpand.id && rowExpand.isToggle}
              timeout="auto"
              unmountOnExit
            >
              {/* {row?.subRow && row?.subRow.length > 0 && ( */}
              <GlobalTableComponent
                headers={subHeader}
                data={row?.subRow}
                // isCheckbox={true}
                // isDraggableRow={true}

                // tableClass={"global-sub-table"}

                isCheckbox={subTableProps?.isCheckbox}
                isDraggableRow={subTableProps?.isDraggableRow}
                isCollapsible={subTableProps?.isCollapsible}
                isHeaderHidden={subTableProps?.isHeaderHidden}
                isLoading={subTableProps?.isLoading}
                // headerClass={"global-subHeader-class"}
                tableClass={"global-sub-table"}
                tableContainerClass={"global-sub-table-container"}
                isHiddenCheckBoxIcon={subTableProps?.isHiddenCheckBoxIcon}
                isHiddenDraggableRowIcon={
                  subTableProps?.isHiddenDraggableRowIcon
                }
                loadingProps={subTableProps?.loadingProps}
                isHiddenRowExpandIcon={subTableProps?.isHiddenRowExpandIcon}
              />
              {/* )} */}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableRowComponent;
