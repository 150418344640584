import { Menu, MenuItem } from "@mui/material";
import React from "react";
import SearchBoard from "../pages/myTask/components/Filters/SearchBoard";

const DropdownWithSearchbar = ({
  anchorEl,
  open,
  setAnchorEl,
  searchQuery,
  setSearchQuery,
  filteredCards,
  handleConfirmationPopup,
  renderItemLabel,
  ref = null,
  showNone = true,
}) => {
  return (
    <Menu
      id="user-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        "aria-labelledby": "epics-board-dropdown",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 14,
            height: 14,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      className="assign-board-owner-list"
      ref={ref}
    >
      <SearchBoard
        onSearch={(val) => setSearchQuery(val)}
        searchQuery={searchQuery}
      />

      {filteredCards?.length === 0 && (
        <MenuItem disabled>
          <em>No results found</em>
        </MenuItem>
      )}
      {showNone && filteredCards?.length > 0 && (
        <MenuItem value="0" onClick={() => handleConfirmationPopup(0)}>
          <em>None</em>
        </MenuItem>
      )}
      {filteredCards?.map((item) => (
        <MenuItem
          key={item.card.card_id}
          value={item.card.card_id}
          onClick={() => {
            handleConfirmationPopup(item.card.card_id);
          }}
        >
          {renderItemLabel(item)}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default DropdownWithSearchbar;
