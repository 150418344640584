import React, { useEffect, useRef, useState } from "react";
import "./description.scss";
import TextEditor from "../../../textEditor/textEditor";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { descriptionUpdate } from "../../../../../../sevices/apiDescription";
import ReadOnlyDescription from "./ReadOnlyDescription";
import ReactQuill from "react-quill";
import { useResponsive } from "../../../../../../hooks/useResponsive";

const Description = ({
  description,
  setTextEditer,
  cardId,
  isTextEditor,
  handleTextEditor,
  accessToUpdate,
  descRef,
  isfocus,
  handleDescriptionSave,
}) => {
  const queryClient = useQueryClient();
  const [textContent, setTextContent] = useState(description);
  const isMobile = useResponsive("down", "md");
  const isUnder321 = useResponsive("down", "400");

  const handleCancel = () => {
    setTextEditer(false);
  };

  useEffect(() => {
    setTextContent(description);
  }, [description]);

  // console.log("DESC==", descRef.current.value);

  return (
    <>
      <div className={isMobile ? "mobile-text-editor-box" : "text-editor-box"}>
        <ReactQuill
          readOnly={!accessToUpdate}
          modules={Description.modules}
          formats={Description.formats}
          onFocus={() => isfocus(true)}
          onBlur={() => {
            // Delay the handleSave function by 300 milliseconds
            isfocus(false);
            // handleDescriptionSave();
            setTimeout(() => {
              handleDescriptionSave();
            }, 300);
          }}
          ref={descRef}
          value={textContent}
          onChange={(value) => (accessToUpdate ? setTextContent(value) : "")}
          style={{ height: "90%" }}
          className="react-quill-description"
        />
      </div>
      <div
        className={
          isUnder321 ? "mobile-textEditor-btn-div" : "text-editor-btn-div"
        }
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDescriptionSave();
          }}
          sx={{
            "&:disabled": {
              backgroundColor: "rgb(0,0,0,0.12) !important",
              color: "rgb(0,0,0,0.26)",
              cursor: "not-allowed",
            },
          }}
          disabled={!accessToUpdate}
          className="text-editor-btn1"
          variant="contained"
        >
          <div className="text-editor-btn-content">Save</div>
        </Button>
        <Button
          onClick={handleCancel}
          className="text-editor-btn2"
          variant="outlined"
        >
          <div className="text-editor-btn-content">Cancel</div>
        </Button>
      </div>
    </>
  );
};

export default Description;


Description.modules = {
  toolbar: [
    [ { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote", "code-block"],
    [{ align: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
    ],
    ["link", "image", "video"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * Quill Description formats
 * See https://quilljs.com/docs/formats/
 */
Description.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
  "color",
  "background",
];


