import { CircularProgress, TableCell, TableRow } from "@mui/material";
import React from "react";

const LoadingTableRow = ({ colSpan, loadingClass, loadingProps, loader }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={`global-loading ${loadingClass}`}>
        {loader ? loader : <CircularProgress {...loadingProps} />}
      </TableCell>
    </TableRow>
  );
};

export default LoadingTableRow;
