import React, { useEffect, useState } from "react";
import { useSubtasks } from "../../../../utils/reactQuery/cards";

const ChildTaskData = ({ cardId, setSubtaskCards, setIsSubtaskLoading }) => {
  const { subtasks, isLoading } = useSubtasks(cardId);

  useEffect(() => {
    setIsSubtaskLoading(isLoading);
    if (subtasks) {
      setSubtaskCards(subtasks?.data?.data?.responseData);
    }
  }, [subtasks]);
};

export default ChildTaskData;
